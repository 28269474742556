import React from 'react'
import '../src/App.css'
import { BrowserRouter } from 'react-router-dom'
import Header from './Header/Header'
import Home from './Home/Home'
import About from './About/About'
import Footer from './Footer/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <div>
      <BrowserRouter>
      <Header/>
      <Home/>
      <About/>
      <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App