import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  return (
    <footer>
        <div className="footer-container">
          <div className="same-col col-1">
            <div className="heading">
              <span>Quick Links</span>
            </div>
            <ul className='footerlistsame'>
              <li><Link>Home</Link></li>
              <li><Link>Project</Link></li>
              <li><Link to={'/testimonial'}>Luxury</Link></li>
              <li><Link to={'/contact'}>Areas</Link></li>
              <li><Link to={'/contact'}>Developers</Link></li>
              <li><Link to={'/contact'}>Our Team</Link></li>
              <li><Link to={'/contact'}>Sell</Link></li>
            </ul>
          </div>
          <div className="same-col col-2">
            <div className="heading">
              <span>Our Services</span>
            </div>
            <ul className='footerlistsame'>
              <li><Link to={'/flight'}>Flight</Link></li>
              <li><Link to={'/hotel'}>Hotel</Link></li>
              <li><Link to={'/domestic'}>DOMESTIC TOUR PACKAGES</Link></li>
              <li><Link to={'/internation'}>INTERNATIONAL TOUR PACKAGES</Link></li>
              <li><Link to={'/education'}>EDUCATIONAL TOUR PACKAGES</Link></li>
              <li><Link to={'/seminar'}>CONFERENCE AND SEMINARS</Link></li>
            </ul>
          </div>
          <div className="same-col col-3">
            <div className="heading">
              <span>Get In Touch</span>
            </div>
            <div className="adress-box footerlistsame">
              <div className="location adress-same">
                <i class="ri-map-pin-range-line"></i>
                <p>Head Office, Plot No. 111, Noida Sector 136</p>
              </div>
              {/* <div className="number adress-same">
              <i class="ri-phone-fill"></i>
                <a href='tel:+91 9926288424'>9926288424</a>
              </div> */}
              <div className="mail adress-same">Sales:<i class="ri-mail-fill"></i> <a href='mailto:  sales@rianatowers.com'>sales@rianatowers.com</a>
              </div>
              <div className="mail adress-same">Info:<i class="ri-mail-fill"></i> <a href='mailto:   info@rianatowers.com'>info@rianatowers.com</a>
              </div>
              {/* <div className="mail-2 adress-same">
              <i class="ri-mail-fill"></i>
                <a href="mailto:sales@greenwaysinfra.com">sales@greenwaysinfra.com</a>
              </div> */}
            </div>
          </div>
          <div className="same-col col-4">
            <div className="heading">
              <span>Follow On</span>
            </div>
            <div className="footerlistsame social-link">
              <Link target='_blank' to={''}><i class="bi bi-facebook"></i></Link>
              <Link target='_blank' to={'https://twitter.com/tripjarpvtltd'}><i class="bi bi-twitter"></i></Link>
              <Link target='_blank' to={''}><i class="bi bi-linkedin"></i></Link>
              <Link target='_blank' to={'https://www.instagram.com/tripjar21/'}><i class="bi bi-instagram"></i></Link>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <p>Copyright © 2019 Riana Towers | All Right Reserved</p>
          <p>Designed By <a target='_blank' href="https://digiindiasolutions.com/">Digi India Solution</a></p>
        </div>
      </footer>
  )
}

export default Footer