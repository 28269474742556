import React from "react";

function About() {
  return (
    <>
      <div className="container-fluid mt-5" style={{maxWidth:'1250px', background:'lightgrayt'}}>
        <div className="about-content"> 
          <h1>Get Flexible and Profitable Office Spaces with Riana Towers</h1>
          <p className="para">
            Are you looking for an office space to rent a professional and
            inspiring work environment? We are here to provide you with
            fantastic workspace solutions! Since a long time, the cubicles
            layout was the norm of the office spaces. But nowadays, the office
            spaces are seeing a significant change from the traditional
            arrangements to creative and flexible work spaces. The workspace of
            India has incredibly transformed over the decade. The workforce of
            the new generation needs a workspace that encourages flexibility
            with creative infrastructure.
          </p>
          <p className="para">
            We are glad to have years of experience in the construction and
            infrastructure world. We offer you a modern corporate office for
            rent in Delhi NCR. We are proud to say that we are one of the
            dominant construction and infrastructure developer in Delhi NCR. We
            welcome you into an outstanding office space with high-end
            facilities for multinationals and freelancers.
          </p>
        </div>
        <div className="about-content"> 
          <h1>How Riana Towers Helps You?</h1>
          <p className="para">
            We provide you <b> property for rent in Delhi NCR</b> for real
            state, IT/ITES, sports and gaming, corporate offices and other
            sectors. We are continually working to provide you with modern and
            enhanced office designs and furniture. One of the essential factors
            in your office is the office ecosystem that needs to be comfortable
            and productive. Our property for rent in Delhi NCR is organized with
            neat spaces that offer your clients and employees with positive
            vibes and good energy.
          </p>
          <p className="para">
            Here you can get flexible workstations to private cabins. Our
            creative office spaces have polished type flooring, exposed ceiling
            and upgraded lightening. As an entrepreneur and owner of an
            organization, you must have faced issues like lack of proper
            furniture and poor internet speed from your employees or clients.
            The property for rent in Noida Expressway is set up with modern
            office furniture, and high-speed Wi-Fi and internet. In addition to
            the following , we are also vastu compliant. We offers you a vibrant
            and a lively business environment.
          </p>
        </div>
        <div className="about-content"> 
          <h1>Other Facilities We Provide You:</h1>
          <p className="para">
            The best office space for rent in Delhi NCR gives you access to
            window and interior offices, office suites and co-working spaces. We
            also provide a number of meeting spaces as boardrooms and conference
            rooms for presentations, training and other services.
          </p>
          <p className="para">
            Just leave your worries about arranging and maintaining the
            infrastructure of the traditional office and lease furnished office
            space for rent in Delhi NCR to energize and improve your
            organization’s growth and business.
          </p>
          <p className="para">
            At Riana Towers, we present you with guest reception, front desk
            service, power back up, 24/7 security and housekeeping services. You
            also get the mail and courier handling facilities in the economical
            office space for rent in Delhi NCR.
          </p>
          <p className="para">
            The best office space for rent in Noida Expressway grants you nice
            kitchen areas, coffee making facilities, food and Beverage Park with
            restaurant and fun zone along with pleasant guest rooms and driver
            rooms.
          </p>
          <p className="para">
            We have also introduced a very unique and exceptional concept of
            Golden and Green workspaces to adopt corporate sustainability. These
            green workspaces not only create a healthier and happier work
            environment but also reduce stress among the employees. We have made
            sure that our fully furnished office space for rent in Delhi NCR has
            beautiful green zones around them.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
