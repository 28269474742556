import React from "react";
import "./Home.css";
import TypeIt from "typeit-react";
import image1 from "../images/interior-img-1.jpg";
import image2 from "../images/interior-img-2.jpg";
import image3 from "../images/interior-img-3.jpg";
import image4 from "../images/interior-img-4.jpg";
import image5 from "../images/interior-img-5.jpg";
import image6 from "../images/interior-img-6.jpg";
import cowork1 from "../images/cowork1.jpeg";
import cowork2 from "../images/cowork2.jpeg";
import cowork3 from "../images/cowork3.jpeg";
import cowork4 from "../images/cowork4.jpeg";
import cowork5 from "../images/cowork5.jpeg";
import cowork6 from "../images/cowork6.jpeg";
import floors1 from "../images/floors1.jpeg";
import floors2 from "../images/floors2.jpeg";
import floors3 from "../images/floors3.jpeg";
import floors4 from "../images/floors4.jpeg";
import floors5 from "../images/floors5.jpeg";
import floors6 from "../images/floors6.jpeg";
import Slider from "react-slick";
import company1 from "../images/company1.png";
import company2 from "../images/company2.png";
import company3 from "../images/company3.png";
import company4 from "../images/company4.png";
import company5 from "../images/company5.png";
import company6 from "../images/company6.png";

function Home() {
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const card = [
    {
      image: image1,
      title: "Tiger Sky Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 2,305,165",
    },
    {
      image: image2,
      title: "One Sankari - West Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 36,724,000",
    },
    {
      image: image3,
      title: "Topaz Avenue",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 36,724,000",
    },
    {
      image: image4,
      title: "Fairmont Residences Solara Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 952,002",
    },
    {
      image: image5,
      title: "Dubai Harbour Residences",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "Price on request",
    },
    {
      image: image6,
      title: "Hyde Residences",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 3,900,000",
    }
  ];
  const cowork = [
    {
      image: cowork1,
      title: "Tiger Sky Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 2,305,165",
    },
    {
      image: cowork2,
      title: "One Sankari - West Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 36,724,000",
    },
    {
      image: cowork3,
      title: "Topaz Avenue",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 36,724,000",
    },
    {
      image: cowork4,
      title: "Fairmont Residences Solara Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 952,002",
    },
    {
      image: cowork5,
      title: "Dubai Harbour Residences",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "Price on request",
    },
    {
      image: cowork6,
      title: "Hyde Residences",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 3,900,000",
    }
  ];
  const floors = [
    {
      image: floors1,
      title: "Tiger Sky Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 2,305,165",
    },
    {
      image: floors2,
      title: "One Sankari - West Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 36,724,000",
    },
    {
      image: floors3,
      title: "Topaz Avenue",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 36,724,000",
    },
    {
      image: floors4,
      title: "Fairmont Residences Solara Tower",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 952,002",
    },
    {
      image: floors5,
      title: "Dubai Harbour Residences",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "Price on request",
    },
    {
      image: floors6,
      title: "Hyde Residences",
      desc1: "Business Bay",
      desc2: "Tiger Properties",
      button: "From AED 3,900,000",
    }
  ];
  return (
    <>
      <div>
        <div className="Homeimage">
          <div className="overlay">
            <div className="overlay-content">
              <h1>Welcome to Riana Towers</h1>
              <p>
                We are carrying the legacy of years of experience in
                construction and infrastructure. Riana Towers brings a new wave
                of inspiration for born and future leaders.
              </p>
              <p>
                We wish to work to modernise and transform the way India works.
              </p>
              <p>
                We build ultra-modern qualitative and eco- friendly ready to
                move in commercial spaces with a comfortable and healthy
                environment, where quality is never compromised.
              </p>
              <p>
                Land available is in millions of square ft with “Big client
                approach”
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-play mt-5 mb-5">
            <h2>Riana Towers</h2>
            <div className="App">
              <TypeIt
                as="h2"
                options={{
                  startDelay: 900,
                  speed: 50,
                  waitUntilVisible: true,
                }}
              >
                The Power to be the Best
              </TypeIt>
            </div>
            <form action="">
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control w-50"
                  placeholder="Search by project name"
                  name=""
                  id=""
                />
                <button className="btn btn-success">Search</button>
              </div>
            </form>
          </div>
          <div className="row">
            <h1 className="mt-5 mb-4">Buildings</h1>
            {card.map((item, index) => (
              <div className="col-md-4 mb-4">
                <div className="cards">
                  <img src={item.image} width={"100%"} alt="" />
                  <div className="p-3">
                    <h4 style={{ fontFamily: "poppins" }}>
                      <b> {item.title}</b>
                    </h4>
                    <p style={{ fontFamily: "poppins" }}>
                      <i class="bi bi-geo-alt"></i> {item.desc1}
                    </p>
                    <p style={{ fontFamily: "poppins" }}>
                      <i class="bi bi-tools"></i> {item.desc2}
                    </p>
                    <hr />
                    <button className="card-button">{item.button}</button>
                  </div>
                </div>
              </div>
            ))}
            <h1 className="mt-5 mb-4">Co-Working Space</h1>
            {cowork.map((item, index) => (
              <div className="col-md-4 mb-4">
                <div className="cards">
                  <img src={item.image} width={"100%"} alt="" />
                  <div className="p-3">
                    <h4 style={{ fontFamily: "poppins" }}>
                      <b> {item.title}</b>
                    </h4>
                    <p style={{ fontFamily: "poppins" }}>
                      <i class="bi bi-geo-alt"></i> {item.desc1}
                    </p>
                    <p style={{ fontFamily: "poppins" }}>
                      <i class="bi bi-tools"></i> {item.desc2}
                    </p>
                    <hr />
                    <button className="card-button">{item.button}</button>
                  </div>
                </div>
              </div>
            ))}
            <h1 className="mt-5 mb-4">Floors</h1>
            {floors.map((item, index) => (
              <div className="col-md-4 mb-4">
                <div className="cards">
                  <img src={item.image} width={"100%"} alt="" />
                  <div className="p-3">
                    <h4 style={{ fontFamily: "poppins" }}>
                      <b> {item.title}</b>
                    </h4>
                    <p style={{ fontFamily: "poppins" }}>
                      <i class="bi bi-geo-alt"></i> {item.desc1}
                    </p>
                    <p style={{ fontFamily: "poppins" }}>
                      <i class="bi bi-tools"></i> {item.desc2}
                    </p>
                    <hr />
                    <button className="card-button">{item.button}</button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="container bg-white p-1 mt-5">
            <h1 className="text-center mt-3 mb-5">Our Clients</h1>
            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <img className="forclientstyle" src={company1} alt="" />
                </div>
                <div>
                  <img className="forclientstyle" src={company2} alt="" />
                </div>
                <div>
                  <img className="forclientstyle" src={company3} alt="" />
                </div>
                <div>
                  <img className="forclientstyle" src={company4} alt="" />
                </div>
                <div>
                  <img className="forclientstyle" src={company5} alt="" />
                </div>
                <div>
                  <img className="forclientstyle" src={company6} alt="" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
